<template>
  <div class="flex flex-col justify-center w-full">
    <div class="flex flex-col items-center">
      <Logo invert class="w-44" />
      <div class="mt-6 text-3xl font-semibold text-gray-900">Log in to your account</div>
      <div class="mt-3 text-gray-600">Welcome back! Please enter your details.</div>
    </div>
    <UForm class="mt-8" :schema="schema" :state="state" @submit="logIn">
      <UFormGroup label="Email" name="email">
        <UInput
          v-model="state.email"
          class="[&>input]:!px-3.5 [&>input]:!py-2.5"
          placeholder="Enter your email"
        />
      </UFormGroup>
      <UFormGroup class="mt-5" label="Password" name="password">
        <UInput
          v-model="state.password"
          type="password"
          class="[&>input]:!px-3.5 [&>input]:!py-2.5"
          placeholder="Enter your password"
        />
      </UFormGroup>
      <nuxt-link class="mt-6 flex justify-end text-sm" to="/forgot-password"> Forgot password? </nuxt-link>
      <div class="mt-6">
        <UButton
          :loading="loggingIn"
          :disabled="!canSubmitForm"
          class="flex justify-center items-center w-full h-10"
          type="submit"
          >Sign in</UButton
        >
      </div>
    </UForm>
    <p v-if="firebaseError" class="w-full mt-2 text-left text-red-500 text-sm">{{ firebaseError }}</p>
  </div>
</template>

<script setup lang="ts">
import { object, string } from 'yup'
import type { FormSubmitEvent } from '#ui/types'
import { useFirebaseHumanError } from '@hiyield/firebase-errors-human-readable'

const loggingIn = ref(false)
const firebaseError = ref<string>()
const state = reactive({
  email: undefined,
  password: undefined
})

const schema = object({
  email: string().email('Invalid email').required('Required'),
  password: string().min(8, 'Must be at least 8 characters').required('Required')
})

const canSubmitForm = computed(() => {
  return schema.isValidSync(state)
})

const logIn = async (event: FormSubmitEvent<{ email: string; password: string }>) => {
  try {
    const { email, password } = event.data
    loggingIn.value = true
    // log in the user
    await useAuthStore().logIn(email, password)
    loggingIn.value = false
  } catch (e) {
    const firebaseHumanError = useFirebaseHumanError({
      'auth/invalid-credential': 'Invalid email or password'
    })
    firebaseError.value = firebaseHumanError(e)
  } finally {
    loggingIn.value = false
  }
}
</script>
