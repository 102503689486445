<template>
  <div class="w-full flex items-start justify-between text-sm">
    <div>
      <div class="text-[#344054] max-w-52 font-semibold truncate">{{ name }}</div>
      <div class="text-[#475467] max-w-52 truncate">{{ email }}</div>
    </div>
    <UButton
      icon="i-mdi-logout"
      square
      variant="ghost"
      size="lg"
      class="hover:bg-transparent text-[#667085] !ring-0"
      @click="authStore.logOut"
    />
  </div>
</template>

<script lang="ts" setup>
const authStore = useAuthStore()
const { userDoc } = storeToRefs(authStore)

const name = computed(() => {
  const firstName = userDoc.value?.firstName
  const lastName = userDoc.value?.lastName
  // if both firstName and lastName are present, return full name
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  // if only firstName is present, return firstName
  if (firstName) {
    return firstName
  }
  // otherwise, return email
  return userDoc.value?.email
})

const email = computed(() => userDoc.value?.email)
</script>
