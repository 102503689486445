<template>
  <div class="flex-1 mt-6 overflow-y-scroll">
    <NavLink
      v-if="userHasRole(['ctsAdmin', 'superAdmin', 'networkAdmin']).value"
      icon="i-mdi-home-variant-outline"
      text="Dashboard"
      link="/admin/dashboard"
    />
    <NavLink
      v-model="showServiceListingTabs"
      icon="i-mdi-chart-bar"
      text="Service listings"
      show-dropdown
      @click="showServiceListingTabs = !showServiceListingTabs"
    />
    <div v-if="showServiceListingTabs">
      <NavLink
        v-if="userHasRole(['ctsAdmin', 'superAdmin', 'serviceAdmin']).value"
        is-nested
        text="Add new service listing"
        is-button
        @click="addNewServiceListing"
      />
      <NavLink v-if="userHasMoreThanOneService" is-nested text="Overview" link="/admin/services/overview" />
      <NavLink
        v-if="userHasRole(['superAdmin', 'ctsAdmin']).value"
        is-nested
        text="Expired listings"
        link="/admin/services/expired-services"
      />
      <NavLink is-nested text="Change requests" link="/admin/services/change-requests" />
      <NavLink
        v-if="userHasRole(['superAdmin', 'ctsAdmin']).value"
        is-nested
        text="New applications"
        link="/admin/services/new-applications"
      />
      <NavLink
        v-if="userHasRole(['superAdmin', 'ctsAdmin']).value"
        is-nested
        text="Removed listings"
        link="/admin/services/removed-listings"
      />
    </div>
    <div v-if="userHasRole(['superAdmin', 'ctsAdmin']).value">
      <NavLink
        icon="i-mdi-sticker-check-outline"
        text="Email templates"
        link="https://login.sendgrid.com/login/identifier"
        external
        target="_blank"
      />
      <NavLink icon="i-heroicons-users" text="Users" link="/admin/user-management" />
      <NavLink icon="i-heroicons-tag" text="Support tags" link="/admin/support-tags" />
      <NavLink icon="i-heroicons-chat-bubble-left" text="FAQ management" link="/admin/faq-management" />
      <NavLink icon="i-mdi-chart-pie-outline" text="Audit log" link="/admin/audit-log" />
      <NavLink icon="i-mdi-monitor" text="iFrame Management" link="/admin/iframe-management" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { userHasRole } from '~/utils/hasComponentAccess'
const showServiceListingTabs = ref(true)

const { userDoc } = storeToRefs(useAuthStore())

// default user role to lowest level
const userRole = computed(() => userDoc.value?.role || 'internal-correspondence')

const userHasMoreThanOneService = computed(() => {
  // super and cts admins always have access to many services
  if (userRole.value === 'superAdmin' || userRole.value === 'ctsAdmin') {
    return true
  }
  // otherwise, they're a network or service admin
  const servicesLength = userDoc.value?.services?.length || 0
  return servicesLength > 1
})

const addNewServiceListing = () => {
  // determine if the user is CTS admin, super admin or service admin, if they are direct them to the new service page in the admin area
  if (userRole.value === 'ctsAdmin' || userRole.value === 'superAdmin' || userRole.value === 'serviceAdmin') {
    return useRouter().push('/admin/new-service')
  }
  // redirect to the frontend '/register-your-service' page
  else if (userRole.value === 'networkAdmin') {
    return useRouter().push('/register-your-service')
  }
}
</script>
