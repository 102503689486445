<template>
  <NuxtLink :to="link">
    <div
      class="flex items-center transition-colors duration-200 ease-in-out hover:bg-gray-50 justify-between text-[#667085] py-2 px-3 mt-2 cursor-pointer rounded"
      :class="{ 'bg-gray-100': isThisRoute, 'ml-8': isNested }"
    >
      <div class="flex items-center gap-x-3">
        <UIcon v-if="icon" :name="icon" class="!size-5" />
        <div class="font-semibold text-[#344054]">{{ text }}</div>
      </div>
      <UIcon
        v-if="showDropdown"
        class="!size-5"
        name="i-mdi-chevron-down"
        :class="{ 'rotate-180': shouldShowDropdown }"
      />
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  link: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  isButton: Boolean,
  isNested: Boolean,
  showDropdown: Boolean,
  modelValue: Boolean
})

const shouldShowDropdown = defineModel({ type: Boolean, default: false })
const isThisRoute = computed(() => {
  return useRoute().path === props.link
})
</script>
