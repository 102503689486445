<template>
  <div>
    <UBreadcrumb :links="links"></UBreadcrumb>
  </div>
</template>

<script setup lang="ts">
const breadCrumbStore = useBreadcrumbStore()
const { links } = storeToRefs(breadCrumbStore)
</script>

<style scoped></style>
